import React, { useEffect, useState } from "react";
import { Note , NoteType} from '../../types';
import { Edit, Pin, PinOff, StickyNote, Search, Calendar} from 'lucide-react';

const NoteCard: React.FC<{ note: Note, onUpdate: (noteId: number, field: keyof Note, value: any) => void, fillExistingNote : (noteId: number, is_all_notes?: boolean) => void }> = ({ note, onUpdate, fillExistingNote }) => {
  const formattedTime = (() => {
    const noteDate = new Date(note.time);
    const today = new Date();
    // console.log(noteDate.toDateString(), today.toDateString());

    if (noteDate.toDateString() === today.toDateString()) {
      return noteDate.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'});
    } else {
      return noteDate.toLocaleDateString();
    }
   })();
  return (
    <div className="bg-pink-50 p-4 rounded-lg">
      <div className="flex justify-between text-sm text-gray-600">
        <span className="flex items-center gap-1">
          <span className="truncate overflow-hidden max-w-[150px]">
            {note.name?.length ? `${note.name} - ` : ''} {note.type?.name}
          </span>
          {note.isFromScratchPad && (
            <StickyNote size={16} />
          )}
        </span>
        <span className='flex gap-2'>
          <span>{formattedTime}</span>
          <span
            onClick={() => fillExistingNote(note.id, true)}>
            <Edit size={16} />
          </span>
          {note.isImportant && (
            <span className="flex items-center gap-1"
            onClick={() => onUpdate(note.id, 'isImportant', !note.isImportant)}
            >
              <Pin size={16} />
            </span>
          )}
          {!note.isImportant && (
            <span className="flex items-center gap-1"
            onClick={() => onUpdate(note.id, 'isImportant', !note.isImportant)}
            >
              <PinOff size={16} />
            </span>
          )}
        </span>
      </div>
      <p className="mt-2 break-words whitespace-normal overflow-wrap-break-word">{note.content}</p>
    </div>
  );
};
interface NoteModalProps {
    all_notes: Note[];
    onUpdate: (noteId: number, field: keyof Note, value: any) => void;
    fillExistingNote : (noteId: number) => void;
    fetchAllNotes: (search?: string, date?: string) => void
}
const AllNoteModal: React.FC<NoteModalProps> = ({all_notes, onUpdate, fillExistingNote, fetchAllNotes}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [debouncedTerm, setDebouncedTerm] = useState(searchTerm);
    const [selectedDate, setSelectedDate] = useState('');

    // Update debounced value after 300ms delay
    useEffect(() => {
        const timer = setTimeout(() => {
            setDebouncedTerm(searchTerm);
        }, 300);

        return () => {
            clearTimeout(timer);
        };
    }, [searchTerm]);

    // Call onSearch with debounced value
    useEffect(() => {
        fetchAllNotes(debouncedTerm);
    }, [debouncedTerm, ]);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const date = e.target.value;
        console.log('Selected date:', date);
        setSelectedDate(date);
        fetchAllNotes(debouncedTerm, date);
    };

    return (
        <div className="container mx-auto py-2">
            <div className="mb-4 flex gap-4 items-center">
                {/* Search input */}
                <div className="relative flex-1 max-w-md">
                    <input
                        type="text"
                        placeholder="Search notes..."
                        value={searchTerm}
                        onChange={handleSearch}
                        className="w-full px-4 py-2 pl-10 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all"
                    />
                    <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-4 w-4" />
                </div>
                
                {/* Date picker */}
                <div className="relative">
                    <input
                        type="date"
                        value={selectedDate}
                        onChange={handleDateChange}
                        className="px-4 py-2 pl-10 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all"
                    />
                    <Calendar className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-4 w-4" />
                </div>
            </div>

            {/* Notes grid */}
            <div className="mt-8 grid grid-cols-[repeat(auto-fill,minmax(280px,1fr))] gap-2">
                {all_notes.map((note) => (
                    <NoteCard 
                        key={note.id} 
                        note={note} 
                        onUpdate={onUpdate} 
                        fillExistingNote={fillExistingNote} 
                    />
                ))}
            </div>
        </div>
    );
}

export default AllNoteModal;