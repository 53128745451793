import React from 'react';
import { Note , Task} from '../../types';
import { Edit, Pin, PinOff, StickyNote } from 'lucide-react';

const NoteCard: React.FC<{ note: Note, onUpdate: (noteId: number, field: keyof Note, value: any) => void, fillExistingNote : (noteId: number) => void }> = ({ note, onUpdate, fillExistingNote }) => {
  const formattedTime = (() => {
    const noteDate = new Date(note.time);
    const today = new Date();
    // console.log(noteDate.toDateString(), today.toDateString());
    if (noteDate.toDateString() === today.toDateString()) {
      return noteDate.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'});
    } else {
      return noteDate.toLocaleDateString();
    }
   })();
  return (
    <div className="bg-pink-50 p-4 rounded-lg">
      <div className="flex justify-between text-sm text-gray-600">
        <span className="flex items-center gap-1">
          <span className="truncate overflow-hidden max-w-[150px]">
            {note.name?.length ? `${note.name} - ` : ''} {note.type?.name}
          </span>
          {note.isFromScratchPad && (
            <StickyNote size={16} />
          )}
        </span>
        <span className='flex gap-2'>
          <span>{formattedTime}</span>
          <span
            onClick={() => fillExistingNote(note.id)}>
            <Edit size={16} />
          </span>
          {note.isImportant && (
            <span className="flex items-center gap-1"
            onClick={() => onUpdate(note.id, 'isImportant', !note.isImportant)}
            >
              <Pin size={16} />
            </span>
          )}
          {!note.isImportant && (
            <span className="flex items-center gap-1"
            onClick={() => onUpdate(note.id, 'isImportant', !note.isImportant)}
            >
              <PinOff size={16} />
            </span>
          )}
        </span>
      </div>
      <p className="mt-2 break-words whitespace-normal overflow-wrap-break-word">{note.content}</p>
    </div>
  );
};

export default NoteCard;